import React, { useId } from "react";
import useTextInput, { TextInputProps } from "./hooks/use-text-input";
import withForwardRef from "../../hoc/withForwardRef";
import { Container, StyledLabel, StyledInput } from "./style";
// import Length from "./components/Length";

export type { TextInputProps } from "./hooks/use-text-input";

function TextInput(props: TextInputProps): JSX.Element {
  const labelRef = React.useRef<HTMLLabelElement | null>(null);
  const inputId = useId();

  const {
    inputProps,
    LeftIcon,
    RightIcon,
    hasLeftIcon,
    hasRightIcon,
    inputRef,
    inputOnClick,
    onClick,
    isFocused,
    widthOfLabel,
  } = useTextInput(props, labelRef);

  return (
    <Container
      hidden={props.hidden}
      onClick={onClick}
      invalid={Boolean(props.invalid)}
      isFocused={isFocused}
      widthOfLabel={widthOfLabel}
      hasLabel={Boolean(props.label)}
      hasLeftIcon={hasLeftIcon}
      hasValue={Boolean(props.value)}
    >
      {props.label && (
        <StyledLabel
          ref={labelRef}
          htmlFor={inputId}
          invalid={props.invalid}
          required={props.required}
          focused={isFocused || Boolean(props.value)}
          hasLeftIcon={hasLeftIcon}
          hasRightIcon={hasRightIcon}
        >
          {props.label}
        </StyledLabel>
      )}
      {LeftIcon}
      <StyledInput id={inputId} {...inputProps} onClick={inputOnClick} ref={inputRef} />
      {/* <Length value={props.value} maxLength={props.maxLength} /> */}
      {RightIcon}
    </Container>
  );
}

// added to allow fast refresh
const Exported = withForwardRef<HTMLInputElement, TextInputProps>(TextInput);
export default Exported;
