import { GrowthBook } from '@growthbook/growthbook-react'
import { SESSION_STORAGE_KEYS, sessionStorageService } from '../services/sessionStorage'

function getTags(): string[] {
  const sessionTags = (sessionStorageService.get(SESSION_STORAGE_KEYS.tags) || '').split(',').filter(tag => !!tag)
  return sessionTags
}

function getOrganizationId(): string {
  const organizationId = sessionStorageService.get(SESSION_STORAGE_KEYS.organizationId);
  return organizationId || ''
}

export function updateAttributes() {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    tags: getTags(),
    organizationId: getOrganizationId(),
  })
}

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: import.meta.env.VITE_GROWTHBOOK_DEV_MODE === 'true',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    })
  },
})
