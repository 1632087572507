import * as rudderanalytics from "rudder-sdk-js";

type Analytics = Partial<typeof rudderanalytics>;

const DATA_PLANE_URL = "https://textureviow.dataplane.rudderstack.com";
const RUDDERSTACK_WRITE_KEY = import.meta.env.VITE_RUDDERSTACK_WRITE_KEY;

const mockAnalytics: Analytics = {
  page: () => {},
  track: () => {},
  identify: () => {},
};

const shouldTrack =
  RUDDERSTACK_WRITE_KEY && RUDDERSTACK_WRITE_KEY.trim() !== "";
export const analytics: Analytics = shouldTrack
  ? rudderanalytics
  : mockAnalytics;

// Initialize the RudderStack SDK
if (shouldTrack) {
  rudderanalytics.ready(() => {
    console.log("Rudder SDK is ready to push events.");
  });
  rudderanalytics.load(RUDDERSTACK_WRITE_KEY, DATA_PLANE_URL);
} else {
  console.warn(
    "RudderStack write key not found, skipping analytics and mocking it instead."
  );
}
