import React from "react";

interface BulletProps {
  selected?: boolean;
  onClick?: (type: string) => void;
  label: string;
}

const Bullet: React.FC<BulletProps> = ({ selected, label, onClick }) => {
  const handleOnClick = () => {
    onClick && onClick(label);
  };
  return (
    <span
      onClick={handleOnClick}
      className={`capitalize inline-block py-2 px-4 rounded-full cursor-pointer ${
        selected ? "text-textureWhite bg-texturePennBlue" : "text-texturePennBlue bg-opacity-8 bg-textureGray/[0.08] "
      }`}
    >
      {label}
    </span>
  );
};

export default Bullet;
