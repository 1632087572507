import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetNextState } from "../../hooks/useGetNextState";
import { analytics } from "../../analytics";
import { Manufacturer } from "../../interfaces";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import TextInput from "../TextInput";
import Button from "../ButtonComponent";

interface IState extends Manufacturer {
  email: string;
  password: string;
}

export function ConnectMFA() {
  const { state } = useLocationWrapper<IState>();
  const { linkToken = "" } = useParams();
  const { getNextState } = useGetNextState(linkToken);
  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect mfa");
    }
  }, []);

  const [code, setCode] = useState<string>("");

  const handleSubmit = () => {
    const email = state?.email as string;
    const password = state?.password as string;

    getNextState("manufacturers/login/mfa", {
      type: "continue",
      data: {
        email,
        password,
        otpCode: code,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-0 mb-12 lg:mb-0">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          Enter your {state.displayName} MFA code
        </div>
        <img src={state.logoUrl} alt={`${state.displayName} Logo`} className="w-20 d:w-48" />
      </div>
      <div className="w-full sm:w-[30rem]">
        <TextInput label="Code" value={code} onChange={setCode} inputMode="numeric" />
      </div>
      <div className="w-full sm:w-[30rem]">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
    </>
  );
}
