import React from "react";
import { classNames } from "../../utils";

export function Container(props: React.PropsWithChildren) {
  return <div className="flex w-full flex-col gap-2.5">{props.children}</div>;
}

interface RectanglesProps {
  strength: number;
}

export function Rectangles({ strength }: RectanglesProps) {
  if (strength < 0 || strength > 4) {
    throw new Error("Strength prop must be between 0 and 4");
  }
  const color = React.useMemo(() => {
    if (strength === 0) {
      return "bg-texturePennBlue/[0.26]";
    }
    if (strength === 1) {
      return "bg-textureRed";
    }
    if (strength === 2) {
      return "bg-textureYellow";
    }
    return "bg-textureGreen";
  }, [strength]);

  return (
    <div className="flex w-full flex-row items-center justify-between gap-1.5 px-1">
      {Array.from({ length: 5 }).map((_, index) => (
        <Rectangle key={index} color={index <= strength ? color : "bg-texturePennBlue/[0.26]"} />
      ))}
    </div>
  );
}

function Rectangle({ color }: { color: string }) {
  const className = classNames("w-full rounded-md border border-texturePennBlue/[0.26] ", color);
  return <div className={className} />;
}
