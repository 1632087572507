import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IErrorResponse, getUrlForNextState } from "../utils";

interface UnknownDataType {
  [key: string]: unknown;
  slug?: string;
}
export interface INextState {
  next: string;
  data?: UnknownDataType;
  meta?: {
    replace?: boolean;
  };
}

const apiUrl = import.meta.env.VITE_CONNECT_API_BASE_URL || "http://localhost:3000";

/**
 * This function exposes a function getNextState, that allows executing an action
 * on the backend for the current state and navigating to the next state/route.
 *
 * If an error occurs with the action being executed, the user will be navigated to the error state.
 *
 * @param linkToken Link token for current session
 * @returns Object containing getNextState function and loading boolean
 */
export function useGetNextState(linkToken: string) {
  const [nextState, setNextState] = useState<INextState | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IErrorResponse | null>(null);
  const navigate = useNavigate();

  const getNextState = useCallback(
    async function (state: string, action: { type: string; data?: unknown }) {
      try {
        setLoading(true);

        const response = await fetch(`${apiUrl}/v1/links/${linkToken}`, {
          method: "POST",
          body: JSON.stringify({
            state,
            action,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();

        // If response is not ok, throw the data to be caught in the catch block
        // error responses from the API should have a code and message
        if (!response.ok) {
          throw data;
        }

        setNextState(data);
        setLoading(false);
      } catch (err) {
        const error = err as IErrorResponse;

        if (error.code && error.message) {
          // detect if error is from API
          setError(error);
        } else {
          // fallback to generic error
          setError({
            code: "UNKNOWN_ERROR",
            message: "An error occurred.",
          });
        }

        setLoading(false);
      }
    },
    [linkToken],
  );

  useEffect(() => {
    if (error) {
      navigate(`/${linkToken}/error`, {
        state: {
          error,
          prevUrl: window.location.href,
        },
      });
      return;
    }

    if (!nextState) {
      return;
    }

    const nextUrl = getUrlForNextState(linkToken || "", nextState);
    if (nextUrl) {
      navigate(nextUrl, {
        state: nextState.data,
        replace: !!nextState.meta?.replace,
      });
    }
  }, [nextState, error]);

  return {
    getNextState,
    loading,
  };
}
