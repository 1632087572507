import React from "react";
import { classNames } from "../../utils";
import Spinner from "../Spinner";

interface ButtonProps {
  children?: string;
  onClick?: () => void;
  disabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  link?: boolean;
  secondary?: boolean;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button = ({ children, iconLeft, iconRight, onClick, disabled, link, secondary, loading, type = "button" }: ButtonProps) => {
  const className = classNames(
    "text-base rounded-md flex justify-center items-center gap-2.5",
    disabled && "pointer-events-none opacity-80",
    loading && "pointer-events-none",
    link && "bg-transparent font-normal",
    !link && "bg-texturePennBlue text-textureWhite w-full p-5 h-14 hover:bg-texturePennBlue/[0.8]",
    link && secondary && "text-textureIris",
    link && !secondary && "text-texturePennBlue",
  );

  if (loading) {
    return (
      <button type={type} onClick={onClick} className={className} disabled={disabled}>
        <Spinner />
      </button>
    );
  }

  return (
    <button type={type} onClick={onClick} className={className} disabled={disabled}>
      {iconLeft && iconLeft}
      {children}
      {iconRight && iconRight}
    </button>
  );
};

export default Button;
