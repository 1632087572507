import React, { HTMLInputAutoCompleteAttribute, HTMLInputTypeAttribute } from "react";
import usePrevious from "../../../../hooks/usePrevious";
import { isFunction, isUndefined, merge, omit } from "lodash";
import { ForwardRefProps } from "../../../../hoc/withForwardRef";
import Icon from "../../components/Icon";

export interface TextInputProps extends StyleProps, ForwardRefProps<HTMLInputElement> {
  autoComplete?: HTMLInputAutoCompleteAttribute;
  autoFocus?: boolean;

  // style
  type?: HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  inputMode?: "text" | "numeric";
  // functional
  onChange?(newValue: string): void;
  value?: string;
  // added content
  iconLeft?: React.ReactNode | IconFunction;
  iconRight?: React.ReactNode | IconFunction;
  // states
  invalid?: boolean;
  disabled?: boolean;
  required?: boolean;
  hidden?: boolean;
  // events
  onClick?(event: React.MouseEvent<HTMLInputElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  // validation
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  // help
  tooltip?: string | React.ReactNode;
}

type IconFunction = (value?: string) => React.ReactElement;

function renderIcon(icon: React.ReactNode | IconFunction, value?: string) {
  const component = isFunction(icon) ? icon(value) : icon;
  if (!component) return null;
  return <Icon>{component}</Icon>;
}

export interface StyleProps { }

interface UseTextInput {
  LeftIcon: React.ReactNode | null;
  RightIcon: React.ReactNode | null;
  hasRightIcon: boolean;
  hasLeftIcon: boolean;
  inputRef: React.RefObject<HTMLInputElement | null>;
  inputProps: Pick<
    TextInputProps,
    "type" | "onClick" | "onBlur" | "onFocus" | "disabled" | "value" | "placeholder" | "inputMode"
  > & {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  inputOnClick(event: React.MouseEvent<HTMLInputElement>): void;
  isFocused: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  widthOfLabel: number | undefined;
}

export default function useTextInput(
  props: TextInputProps,
  labelRef: React.RefObject<HTMLLabelElement | null>,
): UseTextInput {
  const { isFocused, onFocus, onBlur } = useInputFocus(props);

  const { width: widthOfLabel } = useWidth({ ref: labelRef });

  const previousValue = usePrevious(props.value);

  const inputRef = props.forwardedRef as React.RefObject<HTMLInputElement | null>;

  const shouldResetTextInput = previousValue && isUndefined(props.value);

  React.useEffect(() => {
    // Since undefined is not recognized as a prop we have to set it imperatively
    if (shouldResetTextInput && inputRef?.current?.value) {
      inputRef.current.value = "";
    }
  }, [shouldResetTextInput, inputRef]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(event.target.value);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
    inputRef?.current?.focus();
    props.onClick && props.onClick(event);
  };

  const inputProps = merge(omit(props, ["formik"]), {
    onChange: handleOnChange,
    onFocus,
    onBlur,
    type: props.type ?? "text",
  });

  const LeftIcon = renderIcon(props.iconLeft, props.value);
  const RightIcon = renderIcon(props.iconRight, props.value);
  const hasLeftIcon = Boolean(LeftIcon);
  const hasRightIcon = Boolean(RightIcon);

  function inputOnClick(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
  }

  return {
    inputProps,
    LeftIcon,
    RightIcon,
    inputRef,
    hasLeftIcon,
    hasRightIcon,
    inputOnClick,
    isFocused,
    onClick: handleOnClick,
    widthOfLabel,
  };
}

type UseInputFocusProps = Pick<TextInputProps, "onFocus" | "onBlur">;

function useInputFocus(props: UseInputFocusProps) {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus && props.onFocus(event);
    setIsFocused(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur && props.onBlur(event);
    setIsFocused(false);
  };

  return {
    isFocused,
    onFocus: handleFocus,
    onBlur: handleBlur,
  };
}

type UseWidthProps = {
  ref: React.RefObject<HTMLLabelElement | null>;
};

function useWidth({ ref }: UseWidthProps) {
  const [width, setWidth] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    if (ref.current) {
      // 12 / 16 will always be the ratio as we transform text-base to text-xs
      const width = (ref.current.getBoundingClientRect().width * 12) / 16;
      setWidth(width);
    }
  }, [ref]);

  return { width };
}
