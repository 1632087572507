const Spinner = () => {
  return (
    <div className="animate-spin">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="13" stroke="white" strokeOpacity="0.5" strokeWidth="2" />
        <path d="M16 3C20.6315 3 24.6974 5.42204 27 9.06863" stroke="white" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
};

export default Spinner;
