import React from "react";
import { TextInputProps } from "../../TextInput";

export type PasswordInputProps = Omit<TextInputProps, "label"> & {
  label?: string;
  forgotPasswordCallback?: () => void;
  hideStrength?: boolean;
};

const WEAK_PASSWORD_LENGTH = 1;
const SOSO_PASSWORD_LENGTH = 4;
const STRONG_PASSWORD_LENGTH = 8;
// const VERY_STRONG_PASSWORD_LENGTH = 12;

export default function usePasswordInput(props: PasswordInputProps) {
  const { label = "Password", ...textInputProps } = props;

  const [type, setType] = React.useState("password");

  const strength = React.useMemo(() => {
    if (!props.value) {
      return 0;
    }
    if (props.value?.length <= WEAK_PASSWORD_LENGTH) {
      return 1;
    }
    if (props.value?.length <= SOSO_PASSWORD_LENGTH) {
      return 2;
    }
    if (props.value?.length <= STRONG_PASSWORD_LENGTH) {
      return 3;
    }
    return 4;
  }, [props.value]);

  const toggleType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  // TODO: probably will be removed once the backend error messages come in.
  const text = React.useMemo(() => {
    if (strength === 0) {
      return `Your password must have at least ${STRONG_PASSWORD_LENGTH} characters.`;
    }
    if (strength === 1) {
      return "Weak";
    }
    if (strength === 2) {
      return "So-so";
    }
    if (strength === 3) {
      return "Strong";
    }
    return "Very strong";
  }, [strength]);

  return { strength, textInputProps, type, toggleType, text, label };
}
