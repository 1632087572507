import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetNextState } from "../../../hooks/useGetNextState";
import { analytics } from "../../../analytics";
import { Manufacturer } from "../../../interfaces";
import { useLocationWrapper } from "../../../hooks/useLocationWrapper";

import TextInput from "../../TextInput";
import PasswordInput from "../../PasswordInput";
import Button from "../../ButtonComponent";

import LockIcon from "../ConnectIntro/components/private.svg";
import WhiteLockIcon from "./components/WhiteLockIcon.svg";

interface IState extends Manufacturer {}

export function ConnectCreds() {
  const { state } = useLocationWrapper<IState>();
  const { linkToken = "" } = useParams();
  const { getNextState } = useGetNextState(linkToken);
  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect credentials");
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    setSubmitting(true);
    getNextState("manufacturers/login", {
      type: "continue",
      data: {
        email,
        password,
      },
    });
  };

  const canSignUp = false;
  const canForgetPassword = false;
  const handleSignUp = () => {};
  const handleForgot = () => {};

  return (
    <>
      <div className="w-full flex flex-col items-center gap-8 tp:gap-4 mb-8 lg:mb-0">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          Sign in with your {state.displayName} account
        </div>
        <img src={state.logoUrl} alt={`${state.displayName} Logo`} className="w-20 d:w-28 tp:hidden" />
        {!submitting && (
          <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
            <TextInput label="Email" value={email} onChange={setEmail} />
            {canForgetPassword ? (
              <PasswordInput value={password} onChange={setPassword} forgotPasswordCallback={handleForgot} />
            ) : (
              <PasswordInput value={password} onChange={setPassword} hideStrength />
            )}
          </div>
        )}
        {submitting && (
          <div className="flex flex-col items-center gap-12 tp:gap-4 mt-4 mb-2">
            <img src={LockIcon} alt="shield" className="w-8 h-8" />
            <div className="text-base text-texturePennBlue leading-7 w-48 text-center">
              Securely connecting to your {state.displayName} account...
            </div>
          </div>
        )}
      </div>
      <div className="w-full sm:w-[30rem] flex flex-col items-center gap-8 tp:gap-4">
        <Button
          disabled={!email || !password}
          onClick={handleSubmit}
          loading={submitting}
          iconRight={<img src={WhiteLockIcon} className="w-4 h-4" />}
        >
          Sign In
        </Button>
        {canSignUp && (
          <div className="w-full flex flex-row align-center gap-2 justify-center">
            Don’t have an account?{" "}
            <Button onClick={handleSignUp} link secondary>
              Sign Up
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
