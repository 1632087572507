import React from "react";
import GreenCheckLogo from "../../assets/green-check-logo.svg";
import { classNames } from "../../utils";
import { Manufacturer } from "../../interfaces";

interface ManufacturerItemProps {
  manufacturer: Manufacturer
  onClick?: (manufacturer: Manufacturer) => void;
}

const ManufacturerItem: React.FC<ManufacturerItemProps> = ({
  manufacturer,
  onClick,
}) => {
  const { displayName, logoUrl, connected, disabled } = manufacturer;

  const handleOnClick = () => {
    if (disabled || !onClick) return;
    onClick(manufacturer);
  };

  const wrapperClass = classNames(
    "w-full h-16 cursor-pointer hover:border-textureGreen flex flex-row justify-between items-center gap-4 px-3.5 py-5 border rounded-md",
    disabled && "pointer-events-none opacity-80",
    connected ? "border-textureGreen bg-textureGreen/[0.08]" : "border-textureGray/[0.15]",
  );

  return (
    <div onClick={handleOnClick} className={wrapperClass}>
      <div className="flex flex-row items-center gap-2 justify-start">
        <img src={logoUrl} alt="Logo" className="w-10 flex-shrink-0" />
        <span className="text-base font-normal text-textureGray">{displayName}</span>
      </div>
      {connected ? <ConnectedCheckMark displayName={displayName} /> : null}
    </div>
  );
};

function ConnectedCheckMark({ displayName }: { displayName: string }) {
  return (
    <img
      src={GreenCheckLogo}
      className="w-8 h-8 ml-auto"
      alt="success-logo"
      title={`${displayName} account connected`}
    />
  );
}

export default ManufacturerItem;
