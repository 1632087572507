import React from "react";

import { FeaturesReady, GrowthBookProvider } from "@growthbook/growthbook-react";
import { growthbook } from "./lib/growthbook";

const GrowthBookWrapper = ({ children }: React.PropsWithChildren) => {
  React.useEffect(() => {
    growthbook.loadFeatures();
  }, []);
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <FeaturesReady timeout={1000}>{children}</FeaturesReady>
    </GrowthBookProvider>
  );
};

export default GrowthBookWrapper;
