import React from "react";
import { Divider, CounterItem, FinishedItem } from "./style";

interface CounterProps {
  activeStep: number;
  steps: string[];
  onClick?: (step: number) => void;
  canClickAhead?: boolean;
  filledSteps?: number[];
}

const Counter = ({
  activeStep,
  steps,
  onClick,
  canClickAhead,
  filledSteps,
}: CounterProps) => {
  return (
    <div className="flex flex-row items-start justify-center gap-1 shrink-0">
      {steps.map((_step, index) => (
        <React.Fragment key={index}>
          <CounterItem
            number={index + 1}
            label={""}
            isActive={index === activeStep}
            isDone={index < activeStep}
            onClick={onClick}
            canClick={
              canClickAhead ||
              index <= activeStep ||
              filledSteps?.some((filledStep) => index - 1 <= filledStep)
            }
          />
          <Divider
            isComplete={filledSteps?.includes(index) || false}
            isActive={index === activeStep}
          />
        </React.Fragment>
      ))}
      <FinishedItem isFinished={activeStep === steps.length} />
    </div>
  );
};

export default Counter;
