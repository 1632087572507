import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetNextState } from "../../../hooks/useGetNextState";
import introImage from "../../../assets/intro-layer-cake.svg";
import { analytics } from "../../../analytics";
import { useLocationWrapper } from "../../../hooks/useLocationWrapper";

import ShieldIcon from "./components/shield.svg";
import PrivateIcon from "./components/private.svg";

import Button from "../../ButtonComponent";
import { SESSION_STORAGE_KEYS, sessionStorageService } from "../../../services/sessionStorage";

interface IState {
  name: string;
  organizationId: string;
  tags: string[];
}

export function ConnectIntro() {
  const { linkToken } = useParams();
  const { state } = useLocationWrapper<IState>();
  const { getNextState, loading } = useGetNextState(linkToken || "");

  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect intro");
    }

    sessionStorageService.set(SESSION_STORAGE_KEYS.organizationId, state?.organizationId || "");
    sessionStorageService.set(SESSION_STORAGE_KEYS.tags, (state?.tags || []).join(","));
  }, []);

  const handleContinue = () => {
    getNextState("intro", {
      type: "continue",
    });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between gap-8 tp:gap-0 mb-12 lg:mb-0">
        <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center">
          {state.name} partners with Texture to connect to your energy devices
        </div>
        <img src={introImage} alt="intro" className="w-36 d:w-60 tp:mt-4" />
        <div className="w-72 sm:w-full flex flex-col sm:flex-row items-start sm:items-center justify-around tp:relative -top-4">
          <div className="flex flex-col items-start sm:items-center mb-8 sm:mb-0">
            <span className="text-base font-bold text-texturePennBlue flex flex-row items-center gap-1.5 mb-1.5">
              <img src={ShieldIcon} alt="shield" className="w-4 h-4" />
              Secure
            </span>
            <p className="text-base text-texturePennBlue font-normal leading-5 sm:text-center sm:w-56">
              Your data is stored at industry-leading standards.
            </p>
          </div>

          <div className="flex flex-col items-start sm:items-center">
            <span className="text-base font-bold text-texturePennBlue flex flex-row items-center gap-1.5 mb-1.5">
              <img src={PrivateIcon} alt="shield" className="w-4 h-4" />
              Private
            </span>
            <p className="text-base text-texturePennBlue font-normal leading-5 sm:text-center sm:w-56">
              Your data will be encrypted and not shared or sold.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-[32rem] flex flex-col items-center gap-8 tp:gap-4">
        <div className="text-xs sm:text-sm text-texturePennBlue text-center">
          By pressing “Continue” below you <br className="sm:hidden" />
          consent to our{" "}
          <a href="https://www.texturehq.com/privacy" className="text-textureIris cursor-pointer" target="_blank">
            End User Privacy Policy
          </a>
        </div>
        <Button onClick={handleContinue} disabled={loading}>
          Continue
        </Button>
      </div>
    </>
  );
}
