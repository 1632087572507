import { RumInitConfiguration } from "@datadog/browser-rum";

export const DATADOG_RUM: RumInitConfiguration = {
    applicationId: import.meta.env.VITE_DATADOG_RUM_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_RUM_CLIENT_TOKEN,
    env: import.meta.env.VITE_DATADOG_RUM_ENVIRONMENT,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'connect-web',
}

export const FEATURE_FLAGS = {
    threeCECopyEnabled: '3ce-connect-copy-enabled',
} as const;