import { datadogRum } from '@datadog/browser-rum';

interface IUserAttributes {
    id?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

function formatNameString(firstName?: string, lastName?: string): string | undefined {
    if (!firstName && !lastName) {
        return undefined;
    }

    const formattedFirstName = firstName || '';
    const formattedLastName = lastName ? ` ${lastName}` : '';

    return `${formattedFirstName}${formattedLastName}`;
}

function updateUser({
    id,
    firstName,
    lastName,
    email,
    phone,
}: IUserAttributes): void {
    const currentUser = datadogRum.getUser();
    const name = formatNameString(firstName, lastName);
    datadogRum.setUser({
        ...currentUser,
        // standard attributes
        id,
        name,
        email,
        // custom attributes
        phone,
    });
}

export const datadogRumService = {
    updateUser,
}