import { isUndefined } from "lodash";
import Button from "../../../ButtonComponent";
import { Eye, EyeSlash } from "@phosphor-icons/react";

interface RightIconProps {
  isShowingPlainText: boolean;
  toggleType: () => void;
  forgotPasswordCallback?: () => void;
}

export default function RightIcon({ isShowingPlainText, toggleType, forgotPasswordCallback }: RightIconProps) {
  const TogglerIcon = isShowingPlainText ? (
    <EyeSlash className="h-6 w-6 cursor-pointer" onClick={toggleType} />
  ) : (
    <Eye className="h-6 w-6 cursor-pointer" onClick={toggleType} />
  );
  return (
    <div className="flex flex-row items-center gap-2">
      {TogglerIcon}
      {!isUndefined(forgotPasswordCallback) && forgotPasswordCallback && (
        <Button onClick={forgotPasswordCallback} link secondary>
          Forgot?
        </Button>
      )}
    </div>
  );
}
