import { isRouteErrorResponse, useNavigate, useParams, useRouteError, useSearchParams } from "react-router-dom";
import { Warning } from "@phosphor-icons/react";
import { IErrorResponse } from "../../utils";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";

import Button from "../ButtonComponent";

interface IState {
  error: IErrorResponse;
}

export function ConnectError() {
  const { state } = useLocationWrapper<IState>();
  const [searchParams] = useSearchParams();
  const { linkToken } = useParams();
  const navigate = useNavigate();

  const stateError: IErrorResponse = state?.error;
  const routeError = useRouteError();
  const searchError = searchParams.get("error");

  console.error("ConnectError", stateError, routeError);

  let message = "An error occurred";
  let showTryAgain = true;
  let shouldRestart = false;

  if (isRouteErrorResponse(routeError)) {
    message = routeError.data || message;
    showTryAgain = false;
  } else if (stateError) {
    message = stateError.message || message;
    // little hacky, but we can continue to improve this
    showTryAgain = stateError.code !== "UNAUTHORIZED";
  } else if (searchError) {
    if (searchError.startsWith('oauth_')) {
      message = "We were unable to connect to your account";
    }
  } else if (linkToken) {
    // Likely the user deep linked into a route
    // lets just give them the option to restart the flow
    shouldRestart = true;
  }

  const handleTryAgain = () => {
    if (shouldRestart) {
      navigate(`/${linkToken}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="w-full flex flex-col items-center gap-8 tp:gap-4">
      <Warning weight="fill" className="w-32 h-32 text-texturePennBlue" />
      <div className="text-texturePennBlue font-bold text-xl d:text-4xl tp:text-2xl text-center flex flex-row items-center gap-4">
        <span>{message}</span>
      </div>
      {showTryAgain && (
        <div className="w-full sm:w-[30rem]">
          <Button onClick={handleTryAgain}>Try again</Button>
        </div>
      )}
    </div>
  );
}
