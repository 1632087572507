import { classNames } from "../../utils";

interface DividerProps {
  isComplete: boolean;
  isActive: boolean;
}

export const Divider = ({ isComplete, isActive }: DividerProps) => {
  const className = classNames(
    "h-full rounded-md bg-texturePennBlue transition-texture",
    isActive ? (isComplete ? "w-full" : "w-1/2") : "w-0",
  );
  return (
    <div className="shrink-0 mt-4 h-1 w-4 md:w-7 rounded-md bg-texturePennBlue/[0.15]">
      <div className={className} />
    </div>
  );
};

interface CounterItemProps {
  number: number;
  label: string;
  isActive: boolean;
  isDone: boolean;
  onClick?: (step: number) => void;
  canClick?: boolean;
}

export const CounterItem = ({ number, label, isActive, isDone, onClick, canClick }: CounterItemProps) => {
  const classNameNumber = classNames(
    "flex justify-center items-center text-base font-bold w-9 h-9 rounded-full transition-texture",
    onClick && "cursor-pointer",
    !canClick && "pointer-events-none",
    isDone && "bg-texturePennBlue/[0.15] text-texturePennBlue/[0.5]",
    isActive && "text-white bg-texturePennBlue",
    !isActive && !isDone && "text-texturePennBlue bg-transparent border border-2 border-texturePennBlue/[0.15]",
  );
  const classNamesLabel = classNames(
    "text-xs font-normal leading-4 whitespace-nowrap w-9 flex justify-center",
    isActive ? "text-texturePennBlue" : "text-transparent",
  );
  const handleOnClick = () => {
    onClick && onClick(number - 1);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-1">
      <div onClick={handleOnClick} className={classNameNumber}>
        {number}
      </div>
      <div className={classNamesLabel}>
        <span>{label}</span>
      </div>
    </div>
  );
};

interface FinishedItemProps {
  isFinished: boolean;
}

export const FinishedItem = ({ isFinished }: FinishedItemProps) => {
  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="35"
        viewBox="0 0 32 35"
        className={`${isFinished ? "fill-textureGreen" : ""}`}
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.8016 1.03047C17.4492 -0.343492 14.5508 -0.343492 12.1984 1.03047L3.80162 5.93469C1.44917 7.30866 3.8147e-06 9.84786 3.8147e-06 12.5958V22.4042C3.8147e-06 25.1521 1.44917 27.6913 3.80162 29.0653L12.1984 33.9695C14.5508 35.3435 17.4492 35.3435 19.8016 33.9695L28.1984 29.0653C30.5508 27.6913 32 25.1521 32 22.4042V12.5958C32 9.84785 30.5508 7.30866 28.1984 5.93469L19.8016 1.03047Z"
          fill="white"
        />
        <path
          d="M19.4234 1.6781C17.3046 0.440632 14.6954 0.440632 12.5766 1.6781L4.17987 6.58232C2.06011 7.82039 0.750004 10.1116 0.750004 12.5958V22.4042C0.750004 24.8884 2.0601 27.1796 4.17987 28.4177L12.5766 33.3219C14.6954 34.5594 17.3046 34.5594 19.4234 33.3219L27.8201 28.4177C29.9399 27.1796 31.25 24.8884 31.25 22.4042V12.5958C31.25 10.1116 29.9399 7.82039 27.8201 6.58232L19.4234 1.6781Z"
          stroke="black"
          strokeOpacity="0.15"
          strokeWidth="1.5"
        />
      </svg>
      <CheckIcon isFinished={isFinished} />
    </div>
  );
};

const CheckIcon = ({ isFinished }: FinishedItemProps) => {
  return (
    <div className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform`}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
        <path
          d="M1 5.41304L3.06452 7.5L9 1.5"
          // stroke="red"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={`${isFinished ? "stroke-textureWhite" : "stroke-texturePennBlue"} `}
        />
      </svg>
    </div>
  );
};
