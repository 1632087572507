import { useLocation } from "react-router-dom";

/**
 * Simple wrapper around useLocation hook of react router that offers ability to type state returned from useLocation:
 * https://reactrouter.com/en/main/hooks/use-location
 *
 * @returns Same output as useLocation hook via react router
 */
export function useLocationWrapper<T>() {
  const location = useLocation();
  return {
    ...location,
    state: location.state as T,
  };
}
