import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import LogRocket from "logrocket";
import * as Sentry from "@sentry/react";
import "./index.css";
import { appRouter } from "./App";
import React from "react";
import { datadogRum } from '@datadog/browser-rum';
import GrowthBookWrapper from "./growthbook";
import { DATADOG_RUM } from "./constants";

if (import.meta.env.VITE_LOG_ROCKET_DISABLED !== "true") {
  LogRocket.init("cwjtgl/connect-web");
}

Sentry.init({
  enabled: import.meta.env.VITE_SENTRY_DISABLED !== "true",
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", import.meta.env.VITE_CONNECT_API_BASE_URL],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

datadogRum.init({
  ...DATADOG_RUM,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <GrowthBookWrapper>
      <RouterProvider router={appRouter} />
    </GrowthBookWrapper>
  </React.StrictMode>,
);
