import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ConnectManufacturerSelection } from "./components/connect/ConnectManufacturerSelection";
import { ConnectError } from "./components/connect/ConnectError";
import { ConnectIntro } from "./components/connect/ConnectIntro";
import { ConnectOAuth } from "./components/connect/ConnectOAuth";
import { ConnectStart } from "./components/connect/ConnectStart";
import { ConnectSuccess } from "./components/connect/ConnectSuccess";
import { ConnectManufacturer } from "./components/connect/ConnectManufacturer";
import { ConnectCreds } from "./components/connect/ConnectCreds";
import { ConnectMFA } from "./components/connect/ConnectMFA";
import { ConnectLayout } from "./components/connect/ConnectLayout";
import { ConnectLocation } from "./components/connect/ConnectLocation";
import { ConnectUtility } from "./components/connect/ConnectUtility";
import { ConnectUnsupported } from "./components/connect/ConnectUnsupported";
import { ConnectManufacturerSerialNumberCapture } from "./components/connect/ConnectManufacturerSerialNumberCapture";

export const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<ConnectLayout />}
      errorElement={
        <ConnectLayout hasError>
          <ConnectError />
        </ConnectLayout>
      }
    >
      <Route path="/utility" element={<ConnectUtility />} />

      <Route path="/:linkToken" element={<ConnectStart />} />
      <Route path="/:linkToken/intro" element={<ConnectIntro />} />
      <Route
        path="/:linkToken/manufacturers"
        element={<ConnectManufacturerSelection />}
      />
      <Route
        path="/:linkToken/manufacturers/:manufacturerSlug"
        element={<ConnectManufacturer />}
      />
      <Route
        path="/:linkToken/manufacturers/:manufacturerSlug/login"
        element={<ConnectCreds />}
      />
      <Route
        path="/:linkToken/manufacturers/:manufacturerSlug/login/mfa"
        element={<ConnectMFA />}
      />

      <Route path="/:linkToken/location" element={<ConnectLocation />} />

      <Route path="/:linkToken/oauth:code" element={<ConnectOAuth />} />
      <Route path="/:linkToken/success" element={<ConnectSuccess />} />
      <Route path="/:linkToken/manufacturerUnsupported" element={<ConnectUnsupported />} />
      <Route path="/:linkToken/manufacturerCaptureSerial" element={<ConnectManufacturerSerialNumberCapture />} />
      <Route path="/:linkToken/error" element={<ConnectError />} />
    </Route>
  )
);
