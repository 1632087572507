import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useGetNextState } from "../../hooks/useGetNextState";
import { analytics } from "../../analytics";
import { useLocationWrapper } from "../../hooks/useLocationWrapper";
import { Manufacturer } from "../../interfaces";

import TextInput from "../TextInput";
import Bullet from "../Bullet";
import ManufacturerItem from "../ManufacturerItem";
import SearchIcon from "../../assets/icons/search.svg";

interface IState {
  manufacturers: Manufacturer[];
}

export function ConnectManufacturerSelection() {
  const { state } = useLocationWrapper<IState>();
  const { linkToken } = useParams();
  const { getNextState } = useGetNextState(linkToken || "");
  const navigate = useNavigate();
  const manufacturers: Manufacturer[] = useMemo(() => state.manufacturers || [], [state.manufacturers]);
  useEffect(() => {
    if (analytics && analytics.page) {
      analytics.page("connect manufacturer selection");
    }
  }, []);

  const [searchText, setSearchText] = useState<string>("");
  const [selectedDeviceType, setSelectedDeviceType] = useState<string | undefined>();
  const handleClickDeviceType = (deviceType: string) => {
    if (selectedDeviceType === deviceType) {
      setSelectedDeviceType(undefined);
      return;
    }
    setSelectedDeviceType(deviceType);
  };

  const deviceTypes = useMemo(() => {
    const deviceTypes: string[] = [];
    manufacturers.forEach((manufacturer) => {
      if (!manufacturer.deviceTypes) return;
      manufacturer.deviceTypes.forEach((deviceType) => {
        if (deviceTypes.includes(deviceType)) return;
        deviceTypes.push(deviceType);
      });
    });
    return deviceTypes;
  }, [manufacturers]);

  const handleSelect = (manufacturer: Manufacturer) => {
    try {
      if (analytics && analytics.track) {
        analytics.track("connect manufacturer selected", {
          manufacturer: manufacturer.slug,
        });
      }
    } catch (error) {
      console.log("Failed to track event: ", error);
    }

    // if we are not supported we are going to directly go to the completion state
    if (manufacturer.slug == 'franklinwh') {
      navigate(`/${linkToken}/manufacturerCaptureSerial`, { state: { ...manufacturer } } );
    } else if (manufacturer.unsupported) {
      navigate(`/${linkToken}/manufacturerUnsupported`, { state: { ...manufacturer } } );
    } else {
      getNextState("manufacturers", {
        type: "continue",
        data: {
          selectedManufacturer: manufacturer.slug,
        },
      });
    }
  };

  const filteredManufacturers = useMemo(() => {
    return manufacturers.filter((manufacturer) => {
      if (searchText.length > 0 && !manufacturer.displayName.toLowerCase().includes(searchText.toLowerCase())) {
        return false;
      }
      if (
        selectedDeviceType &&
        (!manufacturer.deviceTypes || !manufacturer.deviceTypes.some((type) => selectedDeviceType === type))
      ) {
        return false;
      }
      return true;
    }).sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [manufacturers, searchText, selectedDeviceType]);

  return (
    <>
      <div className="w-full mb-8 flex flex-col items-center gap-4">
        <div className="text-texturePennBlue mr-auto">
          Select manufacturer
        </div>
        <TextInput
          value={searchText}
          onChange={setSearchText}
          iconLeft={<img src={SearchIcon} alt="search" className="h-8 w-8" />}
          placeholder="Search for your device manufacturer"
        />
        <div className="w-full md:w-auto overflow-x-auto hide-scrollbar pr-4 md:pr-0 relative z-4">
          <div className="flex flex-row gap-3">
            {deviceTypes.map((deviceType) => (
              <Bullet
                label={deviceType}
                key={deviceType}
                selected={selectedDeviceType === deviceType}
                onClick={handleClickDeviceType}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-full grow overflow-y-auto">
        <div className="w-full md:grid grid-cols-2 gap-4 gap-y-2">
          {filteredManufacturers.map((manufacturer: Manufacturer) => (
            <div className="mb-2 md:mb-0" key={manufacturer.slug}>
              <ManufacturerItem manufacturer={manufacturer} onClick={handleSelect} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
